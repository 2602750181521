import { ImageSet, SliceClass } from 'interfaces';
import { checkForEmptyPrismicHtml } from '../helpers/check-for-empty-prismic-html';
import { prismicToHtmlString } from '../helpers/prismic-to-html-string';
import { OfferDocument } from '../types.generated.interface';

export class Offer<PLI> {
  id: string;
  content: string;
  useCustomContent: boolean;
  images?: ImageSet;
  contentAlignment: string;
  title: string;
  titleAlignment: string;
  normalPrice: string;
  promoPrice?: string;
  pricePrefix?: string;
  priceSize?: string;
  textAfterPrice?: string;
  checkMarks?: { chek_mark_content: string }[];
  textAfterCheckMarks?: string;
  productListItem?: PLI;

  constructor(
    prismicData: OfferDocument,
    content: string,
    images: ImageSet,
    productListItem?: PLI,
    public slices?: SliceClass[]
  ) {
    this.id = prismicData.id;
    this.productListItem = productListItem;
    this.content = content;
    this.images = images;
    const data = prismicData.data;
    this.useCustomContent = data.use_custom_content;
    this.contentAlignment = data.content_alingment;
    this.title = checkForEmptyPrismicHtml(data.title);
    this.titleAlignment = data.title_alignment;
    this.normalPrice = data.normal_price;
    this.promoPrice = data.promo_price;
    this.pricePrefix = data.price_prefix;
    this.priceSize = data.price_size;
    this.textAfterPrice = prismicToHtmlString(data.text_after_price);
    if (data.check_marks && data.check_marks.length > 0) {
      this.checkMarks = data.check_marks.map(item => {
        return {
          chek_mark_content: prismicToHtmlString(item.check_mark_content),
        };
      });
    }
    this.textAfterCheckMarks = prismicToHtmlString(data.text_after_check_marks);
  }
}
